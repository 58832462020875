import emptyFunction from "fbjs/lib/emptyFunction";
import { PURCHASE_SOURCE } from "@analytics/enums";
import { guardModalDismissReason } from "enumAddons/modalDismissReason";
import { CASHIER, REFILL } from "enums/cashier";
import {
  CASHIER_SCOPE,
  GLOBAL,
  REFILL_SCOPE,
  RIGHT_SIDE_REFILL_SCOPE,
  RIGHT_SIDE_SCOPE,
  SCENE,
  STREAM_SCOPE,
} from "enums/modalScope";
import { LoginPromotionType, ModalType } from "src/enums";
import { getEnabledPaymentProviders } from "src/environment";
import {
  openBottomSheetBottomScreen,
  openCashierV2DrawerBottomScreen,
  openConfirmationBottomScreen,
  openDeleteAccountBottomScreen,
  openDeleteEmailBottomScreen,
  openDeletePhoneNumberBottomScreen,
  openEditEmailBottomScreen,
  openEditPhoneNumberBottomScreen,
  openLandingPageBottomScreen,
  openLandingV3BottomScreen,
  openRefillDrawerBottomScreen,
  openRefillV2DrawerBottomScreen,
} from "state/actionCreators/bottomScreen";
import { DISMISS_MODAL, LOCK_MODAL, SHOW_MODAL } from "state/actionTypes";

export const lockTopModal = () => ({ type: LOCK_MODAL, payload: true });
export const unlockTopModal = () => ({ type: LOCK_MODAL, payload: false });
export const dismissModalWithType = ({ modalType, modalDismissReason }) =>
  guardModalDismissReason(modalDismissReason) && {
    type: DISMISS_MODAL,
    payload: { modalType, modalDismissReason },
  };
export const dismissTopModal = (modalDismissReason) =>
  guardModalDismissReason(modalDismissReason) && {
    type: DISMISS_MODAL,
    payload: { modalDismissReason },
  };

export const dismissBatchModals = (modalTypes) => ({
  type: DISMISS_MODAL,
  payload: { modalType: modalTypes },
  meta: { batch: true },
});

export const openModal = ({
  modalType,
  modalData,
  modalScope = SCENE,
  pathname = document.location.pathname,
  stayOnRedirect = false,
}) => ({
  type: SHOW_MODAL,
  payload: {
    modalType,
    modalData,
    modalScope,
  },
  meta: { pathname, stayOnRedirect },
});

export const openBuyCoinsModal = ({
  offer,
  offerConfig,
  provider,
  lockToProvider,
  purchaseSource,
  target,
  streamerId = "",
  uiComponent,
  previousUiComponent,
  viewType = CASHIER,
  isBackAvailable,
  promotionType,
  promotionValue,
  onSuccess,
  onError,
  onDismiss,
  withBonus = false,
  getWheelConfig,
  shouldDismissOnBack,
  stayOnRedirect,
  purchaseOrigin,
  analyticsParams,
  modalType = ModalType.BUY_COINS,
} = {}) => {
  const isRefill = viewType === REFILL;
  let modalScope = isRefill ? REFILL_SCOPE : CASHIER_SCOPE;

  if (
    modalType === ModalType.CASHIER_MODAL ||
    (modalType === ModalType.CHECKOUT && !isRefill)
  ) {
    modalScope = RIGHT_SIDE_SCOPE;
  } else if (
    modalType === ModalType.REFILL_MODAL ||
    (modalType === ModalType.CHECKOUT && isRefill)
  ) {
    modalScope = RIGHT_SIDE_REFILL_SCOPE;
  }

  return openModal({
    modalType,
    modalScope,
    modalData: {
      offer,
      offerConfig,
      provider,
      purchaseSource,
      target,
      lockToProvider,
      ...(streamerId && { streamerId }),
      uiComponent,
      previousUiComponent,
      viewType,
      isBackAvailable,
      promotionType,
      promotionValue,
      onSuccess,
      onError,
      onDismiss,
      withBonus,
      getWheelConfig,
      shouldDismissOnBack,
      purchaseOrigin,
      analyticsParams,
    },
    stayOnRedirect,
  });
};

export const openCheckoutModal = (props = {}) =>
  openBuyCoinsModal({
    ...props,
    modalType: ModalType.CHECKOUT,
  });

export const openCashierModal = (props = {}) =>
  openBuyCoinsModal({
    ...props,
    modalType: ModalType.CASHIER_MODAL,
  });

export const openRefillModal = (props = {}) =>
  openBuyCoinsModal({
    ...props,
    modalType: ModalType.REFILL_MODAL,
  });

export const openLandingPageModal = ({ place, trackingId, onDismiss } = {}) =>
  openModal({
    modalType: ModalType.LANDING,
    modalData: {
      place,
      trackingId,
      onDismiss,
    },
  });

export const openLandingV3Modal = ({ place, trackingId, onDismiss } = {}) =>
  openModal({
    modalType: ModalType.LANDING_MODAL,
    modalScope: RIGHT_SIDE_SCOPE,
    modalData: {
      place,
      trackingId,
      onDismiss,
    },
  });

export const openDeleteAccountModal = () =>
  openModal({
    modalType: ModalType.DELETE_ACCOUNT_MODAL,
    modalData: {},
  });

export const openDeletePhoneNumberModal = () =>
  openModal({
    modalType: ModalType.PROFILE_SETTINGS_DELETE_PHONE_NUMBER,
    modalData: {},
  });

export const openDeleteMessageRequestModal = ({ title }) =>
  openModal({
    modalType: ModalType.DELETE_MESSAGE_REQUEST,
    modalData: { title },
  });

export const openEditPhoneNumberModal = ({ onOutsideClickDismiss }) =>
  openModal({
    modalType: ModalType.PROFILE_SETTINGS_EDIT_PHONE_NUMBER,
    modalData: {
      onOutsideClickDismiss,
    },
  });

export const openDeleteEmailModal = ({ email }) =>
  openModal({
    modalType: ModalType.PROFILE_SETTINGS_DELETE_EMAIL,
    modalData: { email },
  });

export const openEditEmailModal = ({ email }) =>
  openModal({
    modalType: ModalType.PROFILE_SETTINGS_EMAIL,
    modalData: { email },
  });

export const openCannotSendGiftModal = ({ target, modalScope } = {}) =>
  openModal({
    modalType: ModalType.CANNOT_SEND_GIFT,
    modalData: { target },
    modalScope,
  });

export const openBuyCoinsOrCannotSendGiftModal = ({
  purchaseSource,
  purchaseOrigin = "",
  offer,
  streamerId,
  withBottomScreen = false,
  viewType,
  uiComponent,
  previousUiComponent,
  onSuccess,
  onError,
  shouldDismissOnBack = false,
  analyticsParams = {},
  onDismiss = emptyFunction,
  modalType,
  isRefillV2MobileEnabled,
  onRefillV2BottomScreenClose,
  isCashierDesignV2Enabled,
  isPaymentFlowV2Enabled,
}) => {
  const providers = getEnabledPaymentProviders();
  if (providers.length === 0) {
    return openCannotSendGiftModal();
  }

  const modalData = {
    purchaseSource,
    offer,
    viewType,
    uiComponent,
    previousUiComponent,
    streamerId,
    onSuccess,
    onError,
    shouldDismissOnBack,
    purchaseOrigin,
    analyticsParams,
    onDismiss,
  };

  if (withBottomScreen) {
    if (isCashierDesignV2Enabled && !offer && viewType === CASHIER) {
      return openCashierV2DrawerBottomScreen({
        screenData: {
          ...modalData,
          onClose: onRefillV2BottomScreenClose,
          onOutsideClickDismiss: onRefillV2BottomScreenClose,
        },
      });
    } else if (isRefillV2MobileEnabled && !offer && viewType === REFILL) {
      return openRefillV2DrawerBottomScreen({
        screenData: {
          ...modalData,
          onClose: onRefillV2BottomScreenClose,
          onOutsideClickDismiss: onRefillV2BottomScreenClose,
        },
      });
    }

    return openRefillDrawerBottomScreen({
      screenData: modalData,
    });
  }

  return (isPaymentFlowV2Enabled ? openCheckoutModal : openBuyCoinsModal)({
    ...modalData,
    modalType,
  });
};
export const openBuyCoinsIfAvailable = () => {
  const providers = getEnabledPaymentProviders();
  if (providers.length > 0) {
    return openBuyCoinsModal({ purchaseSource: PURCHASE_SOURCE.REFILL });
  }

  return null;
};

export const openLoginModal = ({
  promotionType = LoginPromotionType.DEFAULT,
  registrationSource,
  accountId,
  redirectOnDismiss,
  onDismiss,
  onOutsideClickDismiss,
  title,
  onLoginSuccess,
  isBackButtonHidden,
} = {}) =>
  openModal({
    modalType: ModalType.LOGIN,
    modalData: {
      promotionType,
      registrationSource,
      accountId,
      redirectOnDismiss,
      onDismiss,
      onOutsideClickDismiss,
      title,
      onLoginSuccess,
      isBackButtonHidden,
    },
    modalScope: GLOBAL,
  });

export const openRegistrationDisabledModal = () =>
  openModal({ modalType: ModalType.REGISTRATION_DISABLED, modalScope: GLOBAL });

export const openUnsubscribeStreamer = ({ accountId, subscriptionId }) =>
  openModal({
    modalType: ModalType.UNSUBSCRIBE_STREAMER,
    modalData: {
      accountId,
      subscriptionId,
    },
  });

export const openProfileEditErrorModal = ({
  missingName,
  invalidBirthdate,
  minBirthdate,
  maxBirthdate,
}) =>
  openModal({
    modalType: ModalType.PROFILE_EDIT_ERROR,
    modalData: {
      missingName,
      invalidBirthdate,
      minBirthdate,
      maxBirthdate,
    },
  });

export const openAlertModal = ({
  imageUrl,
  title,
  body,
  buttonTitle,
  callback,
}) =>
  openModal({
    modalType: ModalType.ALERT,
    modalScope: GLOBAL,
    modalData: { imageUrl, title, body, buttonTitle, callback },
  });

export const openGiftsDrawerModal = ({ tabId, canBackToGifts = true } = {}) =>
  openModal({
    modalType: ModalType.GIFTS_DRAWER,
    modalData: { tabId, canBackToGifts },
  });

export const openEditProfileModal = () =>
  openModal({ modalType: ModalType.EDIT_PROFILE, modalScope: GLOBAL });

export const openSendMediaToChatModal = (modalData) =>
  openModal({ modalType: ModalType.SEND_MEDIA_TO_CHAT, modalData });

export const openMiniProfileModal = ({
  accountId,
  streamId,
  followSource,
  isRelationToStreamer,
  modalScope = SCENE,
  initialState,
  miniProfileEntranceSource,
  additionalParams,
}) =>
  openModal({
    modalType: ModalType.MINI_PROFILE_MODAL,
    modalData: {
      accountId,
      streamId,
      followSource,
      isRelationToStreamer,
      initialState,
      miniProfileEntranceSource,
      additionalParams,
    },
    modalScope,
  });

export const openSubscribeModal = ({ accountId }) =>
  openModal({
    modalType: ModalType.SUBSCRIBE_MODAL,
    modalData: { accountId },
  });

export const openReportModal = ({ accountId }) =>
  openModal({
    modalType: ModalType.REPORT_PROFILE_MODAL,
    modalData: { accountId },
  });

export const openGiftFromBroadcasterModal = ({ receiverId, giftId, message }) =>
  openModal({
    modalType: ModalType.GIFT_FROM_BC_MODAL,
    modalData: { receiverId, giftId, message },
  });

export const openBlockListModal = () =>
  openModal({
    modalType: ModalType.BLOCK_LIST_MODAL,
    modalData: {},
  });

export const openConfirmationModal = ({
  title,
  confirmText,
  confirm,
  dismissText,
  dismiss,
  className,
  body,
  modalType = ModalType.CONFIRMATION_MODAL,
  modalScope = GLOBAL,
  swapActionButtons,
  ...rest
}) =>
  openModal({
    modalType,
    modalData: {
      title,
      confirmText,
      confirm,
      dismissText,
      dismiss,
      className,
      body,
      swapActionButtons,
      ...rest,
    },
    modalScope,
  });

export const openTcnnModal = (tcnnMessage) =>
  openModal({
    modalType: ModalType.TCNN_MODAL,
    modalData: tcnnMessage,
    modalScope: STREAM_SCOPE,
  });

export const openSocialNetworkAccountsModal = ({ instagramFlow } = {}) =>
  openModal({
    modalType: ModalType.SOCIAL_NETWORK_ACCOUNTS,
    modalData: { instagramFlow },
  });

export const openInstagramPostsModal = ({ accountId }) =>
  openModal({
    modalType: ModalType.INSTAGRAM_POSTS_MODAL,
    modalData: { accountId },
    modalScope: STREAM_SCOPE,
  });

export const openInstagramAllowAllPhotosModal = () =>
  openModal({ modalType: ModalType.INSTAGRAM_ALLOW_ALL_PHOTOS_MODAL });

export const openInstagramChoosePhotosModal = () =>
  openModal({
    modalType: ModalType.INSTAGRAM_CHOOSE_PHOTOS_MODAL,
  });

export const openRedeemGiftCardModalView = ({ purchaseSource }) =>
  openModal({
    modalType: ModalType.REDEEM_GIFT_CARD_MODAL,
    modalScope: GLOBAL,
    modalData: { purchaseSource },
  });

export const openCaptchaModalView = () =>
  openModal({
    modalType: ModalType.CAPTCHA_MODAL,
    modalScope: GLOBAL,
    modalData: {
      disableEsc: true,
    },
  });

export const openRemoveNftCardModal = ({ confirm = false, accountId, lotId }) =>
  openModal({
    modalType: ModalType.NFT_REMOVE_CARD_MODAL,
    modalScope: GLOBAL,
    modalData: {
      confirm,
      accountId,
      lotId,
    },
  });

export const openNotEnoughCoinsModal = ({
  purchaseSource,
  offer,
  streamerId,
  withBottomScreen = false,
  viewType,
  uiComponent,
  previousUiComponent,
  onSuccess,
  shouldDismissOnBack = false,
  offerConfig,
}) => {
  const providers = getEnabledPaymentProviders();
  if (providers.length === 0) {
    return openCannotSendGiftModal();
  }
  if (withBottomScreen) {
    return openRefillDrawerBottomScreen({
      screenData: {
        purchaseSource,
        viewType,
        offer,
        uiComponent,
        previousUiComponent,
        streamerId,
        onSuccess,
        shouldDismissOnBack,
        offerConfig,
      },
    });
  }

  return openBuyCoinsModal({
    purchaseSource,
    offer,
    streamerId,
    uiComponent,
    previousUiComponent,
    viewType,
    onSuccess,
    shouldDismissOnBack,
    offerConfig,
  });
};

export const openLandingPageModalHandler = ({
  place,
  withBottomScreen = false,
  trackingId,
  onDismiss,
  isLandingPageV3Enabled = false,
}) => {
  if (withBottomScreen) {
    if (isLandingPageV3Enabled) {
      return openLandingV3BottomScreen({
        screenData: {
          place,
          trackingId,
          onDismiss,
        },
      });
    }

    return openLandingPageBottomScreen({
      screenData: {
        place,
        trackingId,
        onDismiss,
      },
    });
  }

  if (isLandingPageV3Enabled) {
    return openLandingV3Modal({
      place,
      trackingId,
      onDismiss,
    });
  }

  return openLandingPageModal({ place, trackingId, onDismiss });
};

export const openBottomSheetModalHandler = (
  modalData,
  withBottomScreen = false
) => {
  if (withBottomScreen) {
    return openBottomSheetBottomScreen({
      screenData: {
        ...modalData,
      },
    });
  }

  return openModal({
    modalType: ModalType.BOTTOM_SHEET,
    modalData,
    modalScope: STREAM_SCOPE,
  });
};

export const openDeleteAccountModalHandler = ({ isMobile = false }) => {
  if (isMobile) {
    return openDeleteAccountBottomScreen();
  }

  return openDeleteAccountModal();
};

export const openEditPhoneNumberModalHandler = ({
  isMobile = false,
  onOutsideClickDismiss,
}) => {
  if (isMobile) {
    return openEditPhoneNumberBottomScreen({ onOutsideClickDismiss });
  }

  return openEditPhoneNumberModal({ onOutsideClickDismiss });
};

export const openDeletePhoneNumberModalHandler = ({ isMobile = false }) => {
  if (isMobile) {
    return openDeletePhoneNumberBottomScreen();
  }

  return openDeletePhoneNumberModal();
};

export const openEditEmailModalHandler = ({ isMobile = false, email }) => {
  if (isMobile) {
    return openEditEmailBottomScreen({ email });
  }

  return openEditEmailModal({ email });
};

export const openDeleteEmailModalHandler = ({ isMobile = false, email }) => {
  if (isMobile) {
    return openDeleteEmailBottomScreen({ email });
  }

  return openDeleteEmailModal({ email });
};

export const openDeleteBlockRequestModalHandler = ({ ...props }) => {
  if (props.isMobile) {
    return openConfirmationBottomScreen({ ...props });
  }

  return openConfirmationModal({ ...props });
};
