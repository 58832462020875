import { shouldPolyfill as shouldPolyfillDisplayNames } from "@formatjs/intl-displaynames/should-polyfill";
import { shouldPolyfill as shouldPolyfillGetCanonicalLocales } from "@formatjs/intl-getcanonicallocales/should-polyfill";
import { shouldPolyfill as shouldPolyfillLocale } from "@formatjs/intl-locale/should-polyfill";
import { shouldPolyfill as shouldPolyfillPluralRules } from "@formatjs/intl-pluralrules/should-polyfill";
import { shouldPolyfill as shouldPolyfillRelativeTime } from "@formatjs/intl-relativetimeformat/should-polyfill";
import { fetchTranslations } from "api/translations";
import supportedLocales from "generated/translations/supportedLocales";
import { uniq } from "./utils/miniLodash";

const RIGHT_TO_LEFT_LOCALES = ["ar", "he"];

export const browserUserLocales = uniq(
  [
    ...(navigator.languages || []),
    navigator.language,
    navigator.userLanguage,
  ].filter((x) => x)
);

export const pickBestLocale = (
  supportedLocales,
  userLocales = browserUserLocales,
  transform = (x) => x,
  defaultLocale = "en"
) =>
  uniq(userLocales.map(transform))
    .map((userLocale) =>
      supportedLocales.find((supportedLocale) =>
        transform(supportedLocale).startsWith(userLocale)
      )
    )
    .filter((x) => x)
    .shift() ||
  defaultLocale ||
  supportedLocales[0];

// https://github.com/yahoo/react-intl/wiki/Upgrade-Guide#change-how-messages-are-formatted
function flattenMessages(locale, nestedMessages, prefix = "") {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    let value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === "string") {
      if (RIGHT_TO_LEFT_LOCALES.includes(locale)) {
        value = `\u202B${value}\u202C`;
      }
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(locale, value, prefixedKey));
    }

    return messages;
  }, {});
}

export const pickLocale = () =>
  pickBestLocale(
    Object.keys(supportedLocales),
    browserUserLocales,
    (x) => x.split("-")[0]
  );

let currentDateFnsLocale;
export default async function loadTranslations(locale, signal) {
  document.getElementsByTagName("html")[0].setAttribute("lang", locale);
  if (shouldPolyfillGetCanonicalLocales()) {
    await import(
      /* webpackChunkName: "intl-getcanonicallocales-polyfill" */
      "@formatjs/intl-getcanonicallocales/polyfill"
    );
  }
  if (shouldPolyfillLocale()) {
    await import(
      /* webpackChunkName: "intl-locale-polyfill" */
      "@formatjs/intl-locale/polyfill"
    );
  }
  if (shouldPolyfillPluralRules(locale)) {
    await import(
      /* webpackChunkName: "intl-pluralrules-polyfill-force" */
      "@formatjs/intl-pluralrules/polyfill-force"
    );
    await import(
      /* webpackChunkName: "intl-pluralrules-locale-[request]" */
      /* webpackInclude: /\/(en|ar|es|fr|hi|ru|tr|vi|de|uk|pt|ja|ko|id|it|th|nl|ms|he|ro|fil)\.js$/ */
      `@formatjs/intl-pluralrules/locale-data/${locale}`
    );
  }
  if (shouldPolyfillRelativeTime(locale)) {
    await import(
      /* webpackChunkName: "intl-relativetimeformat-polyfill-force" */
      "@formatjs/intl-relativetimeformat/polyfill-force"
    );
    await import(
      /* webpackChunkName: "intl-relativetimeformat-locale-[request]" */
      /* webpackInclude: /\/(en|ar|es|fr|hi|ru|tr|vi|de|uk|pt|ja|ko|id|it|th|nl|ms|he|ro|fil)\.js$/ */
      `@formatjs/intl-relativetimeformat/locale-data/${locale}`
    );
  }
  if (shouldPolyfillDisplayNames(locale)) {
    /* webpackChunkName: "intl-displaynames-polyfill-force" */
    await import("@formatjs/intl-displaynames/polyfill-force");
    /* webpackChunkName: "intl-displaynames-locale-[request]" */
    /* webpackInclude: /\/(en|ar|es|fr|hi|ru|tr|vi|de|uk|pt|ja|ko|id|it|th|nl|ms|he|ro|fil)\.js$/ */
    await import(`@formatjs/intl-displaynames/locale-data/${locale}`);
  }
  const results = await Promise.all([
    new Promise((resolve) => {
      supportedLocales[locale]()(resolve);
    }),
    fetchTranslations(signal),
  ]);

  const validResults = results.filter((result) => !(result instanceof Error));
  const [messages, guardianMessages = {}] = validResults;

  const { translatedMessages, dateFnsLocale } = messages;

  currentDateFnsLocale = dateFnsLocale;

  return [
    flattenMessages(locale, translatedMessages),
    guardianMessages[locale],
  ];
}

export const getDateFnsLocale = () => currentDateFnsLocale;
