import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
  Nullable,
  addUserSessionScopeReducer,
} from "src/features/marketing/imports";
import { loadUserHashedInfo } from "src/features/marketing/state/asyncAction";
import { SetIsVisitRequestNeededPayload } from "src/features/marketing/state/types";

export interface MarketingState {
  userHashedInfo: Nullable<{
    email: Nullable<string>;
    hashedEmail: Nullable<string>;
    hashedIpAddress: Nullable<string>;
    hashedPhoneNumber: Nullable<string>;
    ipAddress: string;
    phoneNumber: Nullable<string>;
  }>;
  visitData: {
    isVisitRequestNeeded: boolean;
  };
}

const initialState: MarketingState = {
  visitData: {
    isVisitRequestNeeded: false,
  },
  userHashedInfo: null,
};

const slice = createSlice({
  name: "marketing",
  initialState,
  reducers: {
    setMarketingVisitData(
      state,
      action: PayloadAction<SetIsVisitRequestNeededPayload>
    ) {
      state.visitData = action.payload;
    },
  },
  extraReducers(builder) {
    addUserSessionScopeReducer(
      builder.addCase(loadUserHashedInfo.fulfilled, (state, action) => {
        state.userHashedInfo = action.payload;
      }),
      () => initialState
    );
  },
});

export const { setMarketingVisitData } = slice.actions;

export const marketingReducer = slice.reducer;
